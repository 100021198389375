<template>
  <div>
    <div class="container-fluid">
      <PageTitle :noAdd="true" />

      <!-- TEST -->

      <b-tabs>
        <template v-for="(vlang,klang) in lang">
          <b-tab :title="`Page Settings - ${vlang.text}`" :key="klang">
            <bo-card title="Hero Image" footer-tag="footer">
              <b-row class="gutter-2">
                <b-col md="3">
                  <bo-card-img
                    :src="uploader(((content['hero_' + vlang.value] || {}).value || {}).img)"
                    :title="((content['hero_' + vlang.value] || {}).value || {}).title"
                    :isTitleHtml="true"
                  >
                    <template #buttons>
                      <b-button
                        v-if="moduleRole('Edit')"
                        variant="secondary"
                        size="sm"
                        pill
                        @click="editHeroImage(content['hero_' + vlang.value])"
                        v-b-tooltip.hover="'Edit'"
                      >
                        <i class="fas fa-pencil-alt" />
                      </b-button>
                    </template>
                  </bo-card-img>
                </b-col>
              </b-row>
            </bo-card>
            
            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Credibility Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`credibility_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormCredibilitySection${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'credibility-section-setting',
                              content['credibility_' + vlang.value],
                              `VFormCredibilitySection${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <b-col md="12">
                            <div class="form-group">
                              <label class="control-label">
                                Show Section
                                <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `credibility_section_${vlang.value}`">
                                <div>
                                  <InputRadio
                                    v-model="((content['credibility_' + vlang.value] || {}).value || {}).is_show"
                                    label="Yes"
                                    name="show"
                                    option="Y"
                                  />
                                  <InputRadio
                                    v-model="((content['credibility_' + vlang.value] || {}).value || {}).is_show"
                                    label="No"
                                    name="show"
                                    option="N"
                                  />
                                </div>
                                <VValidate
                                  name="Status"
                                  v-model="((content['credibility_' + vlang.value] || {}).value || {}).is_show"
                                  rules="required"
                                />
                              </template>
                              <template v-else>
                                <p>{{ ((content['credibility_' + vlang.value] || {}).value || {}).is_show == 'Y' ? 'Yes': 'No'}}</p>
                              </template>
                            </div>
                          </b-col>

                          <div class="col-12">
                            <div class="form-group">
                              <label for="aboutTitle">
                                Title
                                <span class="text-danger mr5">*</span>
                              </label>

                              <template v-if="editFormId == `credibility_section_${vlang.value}`">
                                <ckeditor
                                  :editor="editor"
                                  v-model="((content['credibility_' + vlang.value] || {}).value || {}).text"
                                  :config="editorConfig"
                                ></ckeditor>
                                <VValidate
                                  :vid="`aboutDescription${vlang.value}`"
                                  name="Title"
                                  v-model="((content['credibility_' + vlang.value] || {}).value || {}).text"
                                  :rules="(mrValidation.credibility_setting || {}).text"
                                />
                              </template>
                              <template v-else>
                                <div
                                  v-html="((content['credibility_' + vlang.value] || {}).value || {}).text"
                                ></div>
                              </template>
                            </div>
                          </div>

                          <div class="col-12">
                            <div class="form-group">
                              <BoTextarea
                                label="Description"
                                placeholder="e.g. Under new management by KLAB, We've elevated the standards to new heights, ensuring the utmost quality and excitement"
                                v-model="((content['credibility_' + vlang.value] || {}).value || {}).description"
                                rules="required|min:3"
                                :editFormId="`credibility_section_${vlang.value}`"
                                :editFormValue="editFormId"
                              />
                            </div>
                          </div>

                          <hr />
                          <b-col md="12" v-if="editFormId == `credibility_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
              
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">About KLAB Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`about_klab_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormAboutKlab${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'about-klab-section-setting',
                              content['about_klab_' + vlang.value],
                              `VFormAboutKlab${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <b-col md="12">
                            <div class="form-group">
                              <label class="control-label">
                                Show Section
                                <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `about_klab_section_${vlang.value}`">
                                <div>
                                  <InputRadio
                                    v-model="((content['about_klab_' + vlang.value] || {}).value || {}).is_show"
                                    label="Yes"
                                    name="show"
                                    option="Y"
                                  />
                                  <InputRadio
                                    v-model="((content['about_klab_' + vlang.value] || {}).value || {}).is_show"
                                    label="No"
                                    name="show"
                                    option="N"
                                  />
                                </div>
                                <VValidate
                                  name="Status"
                                  v-model="((content['about_klab_' + vlang.value] || {}).value || {}).is_show"
                                  rules="required"
                                />
                              </template>
                              <template v-else>
                                <p>{{ ((content['about_klab_' + vlang.value] || {}).value || {}).is_show == 'Y' ? 'Yes': 'No'}}</p>
                              </template>
                            </div>
                          </b-col>
                          <div class="col-4">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group">
                                  <label for="highlightTitle">
                                    Logo KLAB
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `about_klab_section_${vlang.value}`">
                                    <Uploader
                                      :readonly="!moduleRole('Edit')"
                                      v-model="((content['about_klab_' + vlang.value] || {}).value || {}).fallback_img"
                                      :param="{ thumbnail: true }"
                                      :squarePreview="true"
                                      type="logo"
                                      label="Image"
                                      @data="v => ((content['about_klab_' + vlang.value] || {}).value || {}).img = v"
                                    />
                                    <VValidate
                                      name="Logo KLAB"
                                      v-model="((content['about_klab_' + vlang.value] || {}).value || {}).img"
                                      rules="required"
                                    />
                                  </template>
                                  <template v-else>
                                    <br>
                                    <img :src="uploader(((content['about_klab_' + vlang.value] || {}).value || {}).fallback_img)" :alt="((content['about_klab_' + vlang.value] || {}).value || {}).alt_img">
                                  </template>
                                </div>
                              </div>

                              <b-col md="12">
                                <BoField 
                                  label="Alt Image"
                                  placeholder="e.g. Hero Image"
                                  v-model="((content['about_klab_' + vlang.value] || {}).value || {}).alt_img"
                                  :rules="(mrValidation.about_klab_setting || {}).alt_img"
                                  :editFormId="`about_klab_section_${vlang.value}`"
                                  :editFormValue="editFormId"
                                />
                              </b-col>
                            </div>
                          </div>

                          <div class="col-8">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label for="highlightTitle">
                                    Image KLAB
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `about_klab_section_${vlang.value}`">
                                    <Uploader
                                      :readonly="!moduleRole('Edit')"
                                      v-model="((content['about_klab_' + vlang.value] || {}).value || {}).fallback_img_klab"
                                      :param="{ thumbnail: true }"
                                      :squarePreview="true"
                                      type="about_home"
                                      label="Image KLAB"
                                      @data="v => ((content['about_klab_' + vlang.value] || {}).value || {}).img_klab = v"
                                    />
                                    <VValidate
                                      name="Image KLAB"
                                      v-model="((content['about_klab_' + vlang.value] || {}).value || {}).img_klab"
                                      rules="required"
                                    />
                                  </template>
                                  <template v-else>
                                    <br>
                                    <img :src="uploader(((content['about_klab_' + vlang.value] || {}).value || {}).fallback_img_klab)" :alt="((content['about_klab_' + vlang.value] || {}).value || {}).alt_img_klab" class="w-100">
                                  </template>
                                </div>
                              </div>
                              <b-col md="12">
                                <div class="form-group">
                                  <template v-if="editFormId == `about_klab_section_${vlang.value}`">
                                    <BoTextarea
                                      label="Title"
                                      placeholder="e.g. Under new management by KLAB, We've elevated the standards to new heights, ensuring the utmost quality and excitement"
                                      v-model="((content['about_klab_' + vlang.value] || {}).value || {}).title"
                                      :rules="(mrValidation.about_klab_setting || {}).title"
                                    />
                                  </template>
                                  <template v-else>
                                    <div class="form-group">
                                      <label for="highlightTitle">
                                        Title
                                        <span class="text-danger mr5">*</span>
                                      </label>
                                      <p>
                                        {{ ((content['about_klab_' + vlang.value] || {}).value || {}).title }}
                                      </p>
                                    </div>
                                  </template>
                                </div>
                              </b-col>
                              <b-col md="12">
                                <div class="form-group">
                                    <template v-if="editFormId == `about_klab_section_${vlang.value}`">
                                    <BoTextarea
                                      label="Description"
                                      placeholder="e.g. Under new management by KLAB, We've elevated the standards to new heights, ensuring the utmost quality and excitement"
                                      v-model="((content['about_klab_' + vlang.value] || {}).value || {}).description"
                                      :rules="(mrValidation.about_klab_setting || {}).description"
                                    />
                                  </template>
                                  <template v-else>
                                    <div class="form-group">
                                      <label for="highlightTitle">
                                        Description
                                        <span class="text-danger mr5">*</span>
                                      </label>
                                      <p>
                                        {{ ((content['about_klab_' + vlang.value] || {}).value || {}).description }}
                                      </p>
                                    </div>
                                  </template>
                                </div>
                              </b-col>
                              <div class="col-12">
                                <div class="form-group">
                                  <label :for="`aboutKlabButtonText${vlang.value}`">
                                    Button Text
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `about_klab_section_${vlang.value}`">
                                    <b-form-input
                                      :id="`aboutKlabButtonText${vlang.value}`"
                                      v-model="((content['about_klab_' + vlang.value] || {}).value || {}).cta"
                                      :formatter="
                                        (v) => v.replace(/\s\s\s+/, ' ')
                                      "
                                    ></b-form-input>
                                    <VValidate
                                      :vid="`aboutKlabButtonText${vlang.value}`"
                                      name="Title"
                                      v-model="((content['about_klab_' + vlang.value] || {}).value || {}).cta"
                                      :rules="(mrValidation.about_klab_setting || {}).cta"
                                    />
                                  </template>
                                  <template v-else>
                                    <br>
                                    <button type="button" class="btn btn-secondary">
                                      {{ ((content['about_klab_' + vlang.value] || {}).value || {}).cta }}
                                    </button>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <b-col md="12" v-if="editFormId == `about_klab_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
              
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Why JRF Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`why_jrf_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormWhyJRF${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'why-jrf-section-setting',
                              content['why_jrf_' + vlang.value],
                              `VFormWhyJRF${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <b-col md="12">
                            <div class="form-group">
                              <label class="control-label">
                                Show Section
                                <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `why_jrf_section_${vlang.value}`">
                                <div>
                                  <InputRadio
                                    v-model="((content['why_jrf_' + vlang.value] || {}).value || {}).is_show"
                                    label="Yes"
                                    name="show"
                                    option="Y"
                                  />
                                  <InputRadio
                                    v-model="((content['why_jrf_' + vlang.value] || {}).value || {}).is_show"
                                    label="No"
                                    name="show"
                                    option="N"
                                  />
                                </div>
                                <VValidate
                                  name="Status"
                                  v-model="((content['why_jrf_' + vlang.value] || {}).value || {}).is_show"
                                  rules="required"
                                />
                              </template>
                              <template v-else>
                                <p>{{ ((content['why_jrf_' + vlang.value] || {}).value || {}).is_show == 'Y' ? 'Yes': 'No'}}</p>
                              </template>
                            </div>
                          </b-col>
                          <div class="col-md-4">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group">
                                  <label for="aboutTitle">
                                    Title
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `why_jrf_section_${vlang.value}`">
                                    <b-form-input
                                      :id="`whyJRFTitleSection${vlang.value}`"
                                      v-model="((content['why_jrf_' + vlang.value] || {}).value || {}).title"
                                      :formatter="
                                        (v) => v.replace(/\s\s\s+/, ' ')
                                      "
                                    ></b-form-input>
                                    <VValidate
                                      :vid="`whyJRFTitleSection${vlang.value}`"
                                      name="Title"
                                      v-model="((content['why_jrf_' + vlang.value] || {}).value || {}).title"
                                      :rules="(mrValidation.why_jrf_setting || {}).description"
                                    />
                                  </template>
                                  <template v-else>
                                    <h4 class="font-weight-bold">
                                      {{ ((content['why_jrf_' + vlang.value] || {}).value || {}).title }}
                                    </h4>
                                  </template>
                                </div>
                              </div>
                              <b-col md="12">
                                <div class="form-group">
                                    <template v-if="editFormId == `why_jrf_section_${vlang.value}`">
                                    <BoTextarea
                                      label="Description"
                                      placeholder="e.g. Under new management by KLAB, We've elevated the standards to new heights, ensuring the utmost quality and excitement"
                                      v-model="((content['why_jrf_' + vlang.value] || {}).value || {}).description"
                                      :rules="(mrValidation.why_jrf_setting || {}).description"
                                    />
                                  </template>
                                  <template v-else>
                                    <div class="form-group">
                                      <label for="highlightTitle">
                                        Description
                                        <span class="text-danger mr5">*</span>
                                      </label>
                                      <p>
                                        {{ ((content['why_jrf_' + vlang.value] || {}).value || {}).description }}
                                      </p>
                                    </div>
                                  </template>
                                </div>
                              </b-col>
                            </div>
                          </div>
                          <div class="col-md-8">
                            <div class="row">
                              <template v-for="(v1,k) in ((content['why_jrf_' + vlang.value] || {}).value || {}).data">
                                <div class="col-12" :key="k">
                                  <div class="row">
                                    <div :class="editFormId == `why_jrf_section_${vlang.value}` ? 'col-md-4' : 'col-md-1'">
                                      <div class="form-group">
                                        <label>
                                          Icon
                                          <span class="text-danger mr5">*</span>
                                        </label>
                                        <template v-if="editFormId == `why_jrf_section_${vlang.value}`">
                                          <Uploader
                                            :readonly="!moduleRole('Edit')"
                                            v-model="v1.fallback_img"
                                            :param="{ thumbnail: true }"
                                            :squarePreview="true"
                                            type="icon_why_jrf"
                                            label="Image"
                                            @data="v => v1.img = v"
                                          />
                                          <VValidate
                                            name="Icon"
                                            v-model="v1.img"
                                            rules="required"
                                          />
                                        </template>
                                        <template v-else>
                                          <br>
                                          <img :src="uploader(v1.fallback_img)" :alt="v1.alt_img" class="w-100">
                                        </template>
                                      </div>
                                    </div>
                                    <div :class="editFormId == `why_jrf_section_${vlang.value}` ? 'col-md-8' : 'col-md-11'">
                                      <div class="form-group">
                                        <label for="aboutTitle">
                                          Title
                                          <span class="text-danger mr5">*</span>
                                        </label>
                                        <template v-if="editFormId == `why_jrf_section_${vlang.value}`">
                                          <b-form-input
                                            :id="`whyJrfDataTitle${vlang.value}`"
                                            v-model="v1.text"
                                            :formatter="
                                              (v) => v.replace(/\s\s\s+/, ' ')
                                            "
                                          ></b-form-input>
                                          <VValidate
                                            :vid="`whyJrfDataTitle${vlang.value}`"
                                            name="Title"
                                            v-model="v1.text"
                                            rules="required|min:3"
                                          />
                                        </template>
                                        <template v-else>
                                          <h4 class="font-weight-bold">
                                            {{ v1.text }}
                                          </h4>
                                        </template>
                                      </div>
                                      <div class="form-group">
                                        <label for="aboutTitle">
                                          Sub Title
                                          <span class="text-danger mr5">*</span>
                                        </label>
                                        <template v-if="editFormId == `why_jrf_section_${vlang.value}`">
                                          <b-form-input
                                            :id="`whyJrfDataTitle${vlang.value}`"
                                            v-model="v1.sub_text"
                                            :formatter="
                                              (v) => v.replace(/\s\s\s+/, ' ')
                                            "
                                          ></b-form-input>
                                          <VValidate
                                            :vid="`whyJrfDataTitle${vlang.value}`"
                                            name="Sub Title"
                                            v-model="v1.sub_text"
                                            rules="required|min:3"
                                          />
                                        </template>
                                        <template v-else>
                                          <span>
                                            {{ v1.sub_text }}
                                          </span>
                                        </template>
                                      </div>
                                      <div class="form-group" v-if="editFormId == `why_jrf_section_${vlang.value}`">
                                        <button class="btn btn-outline-success" @click="addIcon(k, vlang)">Add</button>
                                        <button v-if="((content['why_jrf_' + vlang.value] || {}).value || {}).data.length > 1" class="btn btn-outline-danger ml-2" @click="removeIcon(k, vlang)">Remove</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </div>
                          </div>

                          <hr />
                          <b-col md="12" v-if="editFormId == `why_jrf_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
              
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Category Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`category_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update <span class="d-none d-sm-inline">Content</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormHeroImage${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'category-section-setting',
                              content['category_' + vlang.value],
                              `VFormHeroImage${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <b-col md="12">
                            <div class="form-group">
                              <label class="control-label">
                                Show Section
                                <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `category_section_${vlang.value}`">
                                <div>
                                  <InputRadio
                                    v-model="((content['category_' + vlang.value] || {}).value || {}).is_show"
                                    label="Yes"
                                    name="show"
                                    option="Y"
                                  />
                                  <InputRadio
                                    v-model="((content['category_' + vlang.value] || {}).value || {}).is_show"
                                    label="No"
                                    name="show"
                                    option="N"
                                  />
                                </div>
                                <VValidate
                                  name="Status"
                                  v-model="((content['category_' + vlang.value] || {}).value || {}).is_show"
                                  rules="required"
                                />
                              </template>
                              <template v-else>
                                <p>{{ ((content['category_' + vlang.value] || {}).value || {}).is_show == 'Y' ? 'Yes': 'No'}}</p>
                              </template>
                            </div>
                          </b-col>
                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group">
                                  <label for="aboutTitle">
                                    Title Section
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `category_section_${vlang.value}`">
                                    <b-form-input
                                      :id="`aboutTitleSection${vlang.value}`"
                                      v-model="((content['category_' + vlang.value] || {}).value || {}).title"
                                      :formatter="
                                        (v) => v.replace(/\s\s\s+/, ' ')
                                      "
                                    ></b-form-input>
                                    <VValidate
                                      :vid="`aboutTitleSection${vlang.value}`"
                                      name="Title"
                                      v-model="((content['category_' + vlang.value] || {}).value || {}).title"
                                      rules="required|min:3|max:200"
                                    />
                                  </template>
                                  <template v-else>
                                    <h4 class="font-weight-bold">
                                      {{ ((content['category_' + vlang.value] || {}).value || {}).title }}
                                    </h4>
                                  </template>
                                </div>
                              </div>

                              <b-col md="12">
                                <div class="form-group">
                                    <template v-if="editFormId == `category_section_${vlang.value}`">
                                    <BoTextarea
                                      label="Description"
                                      placeholder="e.g. Under new management by KLAB, We've elevated the standards to new heights, ensuring the utmost quality and excitement"
                                      v-model="((content['category_' + vlang.value] || {}).value || {}).description"
                                      :rules="(mrValidation.why_jrf_setting || {}).description"
                                    />
                                  </template>
                                  <template v-else>
                                    <div class="form-group">
                                      <label>
                                        Description
                                        <span class="text-danger mr5">*</span>
                                      </label>
                                      <p>
                                        {{ ((content['category_' + vlang.value] || {}).value || {}).description }}
                                      </p>
                                    </div>
                                  </template>
                                </div>
                              </b-col>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="row">
                              <template v-for="(v1,k) in ((content['category_' + vlang.value] || {}).value || {}).data">
                                <template>
                                  <div class="col-4 border py-2" :key="k">
                                    <p># {{k + 1}}</p>
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="form-group">
                                          <label>
                                            Image
                                            <span class="text-danger mr5">*</span>
                                          </label>
                                          <template v-if="editFormId == `category_section_${vlang.value}`">
                                            <Uploader
                                              :readonly="!moduleRole('Edit')"
                                              v-model="v1.fallback_img"
                                              :param="{ thumbnail: true }"
                                              :squarePreview="true"
                                              :type="k < 3 ? 'category_distance' : 'category_distance_wide'"
                                              label="Image"
                                              @data="v => v1.img = v"
                                            />
                                            <VValidate
                                              name="Image"
                                              v-model="v1.img"
                                              rules="required"
                                            />
                                          </template>
                                          <template v-else>
                                            <br>
                                            <img :src="uploader(v1.fallback_img)" :alt="v1.alt_img" width="100%" height="200px">
                                          </template>
                                        </div>
                                      </div>
                                      <div class="col-12">
                                        <BoField 
                                          label="Url"
                                          placeholder="e.g. https://jakartarunningfestival.id/event/kids-race"
                                          v-model="v1.url"
                                          rules=""
                                          :mandatory="false"
                                          :editFormId="`category_section_${vlang.value}`"
                                          :editFormValue="editFormId"
                                        />
                                      </div>
                                      <div class="col-12">
                                        <BoField 
                                          label="Title"
                                          placeholder="e.g. Marathon"
                                          v-model="v1.title"
                                          rules="required|min:3"
                                          :editFormId="`category_section_${vlang.value}`"
                                          :editFormValue="editFormId"
                                        />
                                      </div>
                                      <b-col md="12">
                                        <BoField 
                                          label="Description"
                                          placeholder="e.g. Dash - 13 Oktober 2024"
                                          v-model="v1.description"
                                          rules="required|min:3"
                                          :editFormId="`category_section_${vlang.value}`"
                                          :editFormValue="editFormId"
                                        />
                                      </b-col>
                                    </div>
                                  </div>
                                </template>
                              </template>
                            </div>
                          </div>

                          <hr />
                          <b-col md="12" v-if="editFormId == `category_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
              
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Marquee Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`marquee_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormMarquee{vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'marquee-section-setting',
                              content['marquee_' + vlang.value],
                              `VFormMarquee{vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <template v-if="editFormId == `marquee_section_${vlang.value}`">
                            <template v-for="(v,k) in ((content['marquee_' + vlang.value] || {}).value || {}).data">
                              <b-col md="8" :key="`${k}-data`">
                                <div class="d-flex align-items-center">
                                  <BoField 
                                    label="Text"
                                    placeholder="e.g. Jakarta"
                                    v-model="content['marquee_' + vlang.value].value.data[k]"
                                    rules="required|min:3"
                                    class="w-75"
                                  />
                                  <div class="mt-2 d-flex ml-2">
                                    <button type="button" class="btn btn-success mx-1" @click="addItem(k, ((content['marquee_' + vlang.value] || {}).value || {}).data)">
                                      <i class="fa fa-plus-circle"></i>
                                    </button>
                                    <button type="button" class="btn btn-danger" @click="removeItem(k, ((content['marquee_' + vlang.value] || {}).value || {}).data)">
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </div>
                                </div>
                              </b-col>
                            </template>
                          </template>
                          <template v-else>
                            <div class="marquee_container">
                              <div class="blobs">
                                <div class="blob1"></div>
                              </div>
                              <div class="marquee">
                                <div class="marquee_group">
                                  <template v-for="(v,k) in ((content['marquee_' + vlang.value] || {}).value || {}).data">
                                    <span :key="`${k}ok`">{{ v }}</span>
                                    <div :key="`${k}ol`" class="square-item"></div>
                                  </template>
                                </div>
                                <div class="marquee_group">
                                  <template v-for="(v,k) in ((content['marquee_' + vlang.value] || {}).value || {}).data">
                                    <span :key="`${k}ok`">{{ v }}</span>
                                    <div :key="`${k}ol`" class="square-item"></div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </template>
                          <hr />
                          <b-col md="12" v-if="editFormId == `marquee_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
              
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Partner Section</h5>
                  </div>
                  <div class="col-6 ml-auto d-flex justify-content-end">
                    <div class="text-right mx-2">
                      <button
                        @click.prevent="toggleEditForm(`partner_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                    <div>
                      <button
                        @click.prevent="$router.push({name: 'OfficialSponsor'})"
                        class="btn btn-secondary btn-rounded"
                      >
                        <i class="fas fa-arrow-right m-r-10"></i> Partner List
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormPartnerSection${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'partner-section-setting',
                              content['partner_' + vlang.value],
                              `VFormPartnerSection${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <b-col md="12">
                            <div class="form-group">
                              <label class="control-label">
                                Show Section
                                <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `partner_section_${vlang.value}`">
                                <div>
                                  <InputRadio
                                    v-model="((content['partner_' + vlang.value] || {}).value || {}).is_show"
                                    label="Yes"
                                    name="show"
                                    option="Y"
                                  />
                                  <InputRadio
                                    v-model="((content['partner_' + vlang.value] || {}).value || {}).is_show"
                                    label="No"
                                    name="show"
                                    option="N"
                                  />
                                </div>
                                <VValidate
                                  name="Status"
                                  v-model="((content['partner_' + vlang.value] || {}).value || {}).is_show"
                                  :rules="(mrValidation.about_klab_setting || {}).is_show"
                                />
                              </template>
                              <template v-else>
                                <p>{{ ((content['partner_' + vlang.value] || {}).value || {}).is_show == 'Y' ? 'Yes': 'No'}}</p>
                              </template>
                            </div>
                          </b-col>
                          <b-col md="6">
                            <BoField 
                              label="Title"
                              placeholder="e.g. Our Partner"
                              v-model="((content['partner_' + vlang.value] || {}).value || {}).title"
                              :rules="(mrValidation.about_klab_setting || {}).title"
                              :editFormId="`partner_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </b-col>
                          <b-col md="6">
                            <BoField 
                              label="Button Text"
                              placeholder="e.g. View More"
                              v-model="((content['partner_' + vlang.value] || {}).value || {}).cta"
                              rules="required"
                              :editFormId="`partner_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </b-col>

                          <hr />
                          <b-col md="12" v-if="editFormId == `partner_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
              
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Media Center Section</h5>
                  </div>
                  <div class="col-6 ml-auto d-flex justify-content-end">
                    <div class="text-right mx-2">
                      <button
                        @click.prevent="toggleEditForm(`media_center_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update Content
                      </button>
                    </div>
                    <div>
                      <button
                        @click.prevent="$router.push({name: 'Blog'})"
                        class="btn btn-secondary btn-rounded"
                      >
                        <i class="fas fa-arrow-right m-r-10"></i> Article List
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormMediaCenterSection${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'media-center-section-setting',
                              content['media_center_' + vlang.value],
                              `VFormMediaCenterSection${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <b-col md="12">
                            <div class="form-group">
                              <label class="control-label">
                                Show Section
                                <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `media_center_section_${vlang.value}`">
                                <div>
                                  <InputRadio
                                    v-model="((content['media_center_' + vlang.value] || {}).value || {}).is_show"
                                    label="Yes"
                                    name="show"
                                    option="Y"
                                  />
                                  <InputRadio
                                    v-model="((content['media_center_' + vlang.value] || {}).value || {}).is_show"
                                    label="No"
                                    name="show"
                                    option="N"
                                  />
                                </div>
                                <VValidate
                                  name="Status"
                                  v-model="((content['media_center_' + vlang.value] || {}).value || {}).is_show"
                                  :rules="(mrValidation.about_klab_setting || {}).is_show"
                                />
                              </template>
                              <template v-else>
                                <p>{{ ((content['media_center_' + vlang.value] || {}).value || {}).is_show == 'Y' ? 'Yes': 'No'}}</p>
                              </template>
                            </div>
                          </b-col>
                          <b-col md="6">
                            <BoField 
                              label="Title"
                              placeholder="e.g. Our media_center"
                              v-model="((content['media_center_' + vlang.value] || {}).value || {}).title"
                              :rules="(mrValidation.about_klab_setting || {}).title"
                              :editFormId="`media_center_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </b-col>
                          <b-col md="6">
                            <BoField 
                              label="Button Text"
                              placeholder="e.g. View More"
                              v-model="((content['media_center_' + vlang.value] || {}).value || {}).cta"
                              rules="required"
                              :editFormId="`media_center_section_${vlang.value}`"
                              :editFormValue="editFormId"
                            />
                          </b-col>

                          <hr />
                          <b-col md="12" v-if="editFormId == `media_center_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
              
            </div>

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Highlight Pre Register Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click.prevent="toggleEditForm(`highlight_section_${vlang.value}`)"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="fas fa-edit m-r-10"></i> Update <span class="d-none d-sm-inline">Content</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver
                      v-slot="{ handleSubmit }"
                      :ref="`VFormHighlight${vlang.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'highlight-section-setting',
                              content['highlight_pre_register_' + vlang.value],
                              `VFormHighlight${vlang.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <b-col md="12">
                            <div class="form-group">
                              <label class="control-label">
                                Show Section
                                <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `highlight_section_${vlang.value}`">
                                <div>
                                  <InputRadio
                                    v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).is_show"
                                    label="Yes"
                                    name="show"
                                    option="Y"
                                  />
                                  <InputRadio
                                    v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).is_show"
                                    label="No"
                                    name="show"
                                    option="N"
                                  />
                                </div>
                                <VValidate
                                  name="Status"
                                  v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).is_show"
                                  rules="required"
                                />
                              </template>
                              <template v-else>
                                <p>{{ ((content['highlight_pre_register_' + vlang.value] || {}).value || {}).is_show == 'Y' ? 'Yes': 'No'}}</p>
                              </template>
                            </div>
                          </b-col>
                          <div class="col-4">
                            <div class="form-group">
                              <label for="highlightTitle">
                                Background Image
                                <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `highlight_section_${vlang.value}`">
                                <Uploader
                                  :readonly="!moduleRole('Edit')"
                                  v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).fallback_img"
                                  :param="{ thumbnail: true }"
                                  :squarePreview="true"
                                  type="highlight_image"
                                  label="Image"
                                  @data="v => ((content['highlight_pre_register_' + vlang.value] || {}).value || {}).img = v"
                                />
                                <VValidate
                                  name="Background Image"
                                  v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).img"
                                  rules="required"
                                />
                              </template>
                              <template v-else>
                                <br>
                                <img :src="uploader(((content['highlight_pre_register_' + vlang.value] || {}).value || {}).fallback_img)" :alt="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).alt_img" class="w-100">
                              </template>
                            </div>

                            <div class="form-group">
                              <label for="highlightTitle">
                                Background Image Small
                                <span class="text-danger mr5">*</span>
                              </label>
                              <template v-if="editFormId == `highlight_section_${vlang.value}`">
                                <Uploader
                                  :readonly="!moduleRole('Edit')"
                                  v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).fallback_img_small"
                                  :param="{ thumbnail: true }"
                                  :squarePreview="true"
                                  type="highlight_image_small"
                                  label="Image"
                                  @data="v => ((content['highlight_pre_register_' + vlang.value] || {}).value || {}).img_small = v"
                                />
                                <VValidate
                                  name="Background Image Small"
                                  v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).img_small"
                                  rules="required"
                                />
                              </template>
                              <template v-else>
                                <br>
                                <img :src="uploader(((content['highlight_pre_register_' + vlang.value] || {}).value || {}).fallback_img_small)" :alt="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).alt_img" class="w-100">
                              </template>
                            </div>
                          </div>
                            
                          <div class="col-12 col-sm-8">
                            <div class="row">
                              <div class="col-12">
                                <div class="form-group">
                                  <label for="highlightTitle">
                                    Title
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `highlight_section_${vlang.value}`">
                                    <b-form-input
                                      :id="`highlightTitle${vlang.value}`"
                                      v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).title"
                                      :formatter="
                                        (v) => v.replace(/\s\s\s+/, ' ')
                                      "
                                    ></b-form-input>
                                    <VValidate
                                      :vid="`highlightTitle${vlang.value}`"
                                      name="Title"
                                      v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).title"
                                      rules="required|min:3|max:200"
                                    />
                                  </template>
                                  <template v-else>
                                    <h4 class="font-weight-bold">
                                      {{ ((content['highlight_pre_register_' + vlang.value] || {}).value || {}).title }}
                                    </h4>
                                  </template>
                                </div>
                              </div>
                              <b-col md="12">
                                <template v-if="editFormId == `highlight_section_${vlang.value}`">
                                  <BoTextarea
                                    label="Description"
                                    placeholder="e.g. Tantang Diri Anda untuk Menaklukkan Jalanan Jakarta"
                                    v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).description"
                                    rules=""
                                    :mandatory="false"
                                  />
                                </template>
                                <template v-else>
                                  <div class="form-group">
                                    <label for="highlightTitle">
                                      Description
                                    </label>
                                    <p>
                                      {{ ((content['highlight_pre_register_' + vlang.value] || {}).value || {}).description }}
                                    </p>
                                  </div>
                                </template>
                              </b-col>
                              <div class="col-12">
                                <div class="form-group">
                                  <label :for="`highlightButtonText${vlang.value}`">
                                    Button Text
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `highlight_section_${vlang.value}`">
                                    <b-form-input
                                      :id="`highlightButtonText${vlang.value}`"
                                      v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).cta"
                                      :formatter="
                                        (v) => v.replace(/\s\s\s+/, ' ')
                                      "
                                    ></b-form-input>
                                    <VValidate
                                      :vid="`highlightButtonText${vlang.value}`"
                                      name="Title"
                                      v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).cta"
                                      rules="required|min:3|max:200"
                                    />
                                  </template>
                                  <template v-else>
                                    <br>
                                    <button type="button" class="btn btn-secondary">
                                      {{ ((content['highlight_pre_register_' + vlang.value] || {}).value || {}).cta }}
                                    </button>
                                  </template>
                                </div>
                              </div>

                              <div class="col-12">
                                <div class="form-group">
                                  <label :for="`racedate${vlang.value}`">
                                    Countdown - Race Date
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `highlight_section_${vlang.value}`">
                                    <v-date-picker class="inline-block h-full" v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).date">
                                      <template v-slot="{ inputValue, togglePopover }">
                                        <div class="flex items-center">
                                          <button
                                            type="button"
                                            class="p-2 bg-blue-100 border border-blue-200 hover:bg-blue-200 text-blue-600 rounded-l focus:bg-blue-500 focus:text-white focus:border-blue-500 focus:outline-none"
                                            @click="togglePopover()"
                                          >
                                            <i class="ti ti-calendar"></i>
                                          </button>
                                          <input
                                            :value="inputValue"
                                            class="bg-white text-gray-700 w-full py-1 px-2 appearance-none border rounded-r focus:outline-none focus:border-blue-500"
                                            readonly
                                          />
                                        </div>
                                      </template>
                                    </v-date-picker>
                                    <VValidate
                                      :vid="`racedate${vlang.value}`"
                                      name="Race Date"
                                      v-model="((content['highlight_pre_register_' + vlang.value] || {}).value || {}).date"
                                      rules="required"
                                    />
                                  </template>
                                  <template v-else>
                                    <h4 class="font-weight-bold">
                                      {{ formatDateShort(((content['highlight_pre_register_' + vlang.value] || {}).value || {}).date) }}
                                    </h4>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <b-col md="12" v-if="editFormId == `highlight_section_${vlang.value}`">
                            <div class="text-right mt-4">
                              <b-button
                                type="button"
                                @click="cancelEditForm"
                                class="btn btn-rounded btn_light mr-2"
                              >
                                Cancel
                              </b-button>
                              <b-button
                                v-if="moduleRole('Edit')"
                                type="submit"
                                variant="primary"
                                class="btn-rounded"
                              >
                                Update
                              </b-button>
                            </div>
                          </b-col>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
              
            </div>


            <!-- <div class="card">
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col-6">
                    <h5 class="card-title">Gallery KLAB Section</h5>
                  </div>
                  <div class="col-6 ml-auto">
                    <div class="text-right">
                      <button
                        @click="$router.push({name: 'GalleryKlab'})"
                        class="btn btn-primary btn-rounded"
                      >
                        <i class="ti ti-angle-right m-r-10"></i> <span class="d-none d-sm-inline">See</span> Gallery
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </b-tab>
        </template>
        <template v-for="(vlangseo,klangseo) in lang">
          <b-tab :title="`SEO Settings - ${vlangseo.text}`" :key="`seo_${klangseo}`">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-10">
                    <h5 class="card-title">SEO Settings</h5>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <ValidationObserver 
                      v-slot="{ handleSubmit }" 
                      :ref="`VFormSeo${vlangseo.value}`"
                    >
                      <form
                        @submit.prevent="
                          handleSubmit(
                            updateContent(
                              'seo-setting',
                              content['seo_' + vlangseo.value],
                              `VFormSeo${vlangseo.value}`,
                              false,
                              true
                            )
                          )
                        "
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label :for="`seoTitle${vlangseo.value}`">
                                    Title
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-input
                                      :id="`seoTitle${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                    ></b-form-input>
                                    <VValidate
                                      :vid="`seoTitle${vlangseo.value}`"
                                      name="Title"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).title"
                                      rules="required|min:3|max:200"
                                    />
                                  </template>
                                  <template v-else>
                                    <p class="font-weight-bold">{{ ((content['seo_' + vlangseo.value] || {}).value || {}).title }}</p>
                                  </template>
                                </div>
                              </div>
                              <div class="col-12 col-md-8">
                                <div class="form-group">
                                  <label>
                                    Description
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <template v-if="editFormId == `seo${vlangseo.value}`">
                                    <b-form-textarea
                                      :id="`seoDescription${vlangseo.value}`"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      :formatter="(v) => v.replace(/\s\s\s+/, ' ')"
                                      rows="5"
                                    ></b-form-textarea>
                                    <VValidate
                                      :vid="`seoDescription${vlangseo.value}`"
                                      name="Description"
                                      v-model="((content['seo_' + vlangseo.value] || {}).value || {}).description"
                                      rules="required|min:10|max:500"
                                    />
                                  </template>
                                  <template v-else>
                                    <div v-html="((content['seo_' + vlangseo.value] || {}).value || {}).description"></div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="moduleRole('Edit')">
                          <div class="col-12">
                            <hr />
                            <div class="text-right">
                              <template v-if="editFormId == `seo${vlangseo.value}`">
                                <button
                                  type="button"
                                  @click="editFormId = ''"
                                  class="btn btn-rounded btn-light mr-2"
                                >
                                  Cancel
                                </button>
                                <button type="submit" class="btn btn-rounded btn-primary">
                                  Save
                                </button>
                              </template>
                              <template v-else>
                                <button
                                  type="button"
                                  @click="editFormId = `seo${vlangseo.value}`"
                                  class="btn btn-rounded btn-primary"
                                >
                                  Ubah
                                </button>
                              </template>
                            </div>
                          </div>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </template>
      </b-tabs>

      <ValidationObserver v-slot="{ handleSubmit }" ref="VFormHeroImage">
        <b-modal
          id="heroImageModal"
          size="md"
          title="Edit Hero Image"
          @ok.prevent="
            handleSubmit(
              updateContent(
                'hero-image',
                inputHeroImage,
                'VFormHeroImage',
                'heroImageModal'
              )
            )
          "
        >
          <template #default>
            <div class="infoHTML"></div>
            <b-form 
              @submit.prevent="
                  handleSubmit(
                    updateContent(
                      'hero-image',
                      inputHeroImage,
                      'VFormHeroImage'
                    )
                  )
                "
            >
              <b-row>
                <b-col md="12">
                  <BoField 
                    label="Title"
                    placeholder="e.g. Experience the Ultimate Running Festival: Jakarta Running Festival 2024"
                    v-model="(inputHeroImage.value || {}).title"
                    :rules="mrValidation.hero_image.title"
                  />
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Sub Title"
                    placeholder="e.g. Beyond Racing: A Vibrant Expo and Unforgettable Moments Await at Indonesia's Finest Running Event!"
                    v-model="(inputHeroImage.value || {}).sub_title"
                    :rules="mrValidation.hero_image.sub_title"
                  />
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Slogan"
                    placeholder="e.g. #LangkahBersama"
                    v-model="(inputHeroImage.value || {}).slogan"
                    rules=""
                    :mandatory="false"
                  />
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="(inputHeroImage.value || {}).fallback_img"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="hero_image"
                      label="Image"
                      @data="v => (inputHeroImage.value || {}).img = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="(inputHeroImage.value || {}).img"
                      :rules="mrValidation.hero_image.img"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>
                      Small Image <span class="text-danger mr5">*</span>
                    </label>
                    <Uploader
                      :readonly="!moduleRole('Edit')"
                      v-model="(inputHeroImage.value || {}).fallback_img_small"
                      :param="{ thumbnail: true }"
                      :squarePreview="true"
                      type="hero_small"
                      label="Image"
                      @data="v => (inputHeroImage.value || {}).img_small = v"
                    />
                    <VValidate
                      name="Image"
                      v-model="(inputHeroImage.value || {}).img_small"
                      :rules="mrValidation.hero_image.img_small"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <BoField 
                    label="Alt Image"
                    placeholder="e.g. Hero Image"
                    v-model="(inputHeroImage.value || {}).alt_img"
                    :rules="(mrValidation.hero_image || {}).alt_img"
                  />
                </b-col>
                <hr />
              </b-row>
            </b-form>
          </template>

          <template #modal-footer="{ ok, cancel }">
            <b-button variant="outline-secondary" @click="cancel()">
              Cancel
            </b-button>
            <b-button variant="info" @click="ok()">
              Submit
            </b-button>
          </template>
        </b-modal>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import GlobalVue from "@libs/Global";
import PageTitle from "@view/include/PageTitle";

import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@libs/ckeditor5-custom-build/build/ckeditor";
import { extend } from "vee-validate";
import assign from "lodash/assign";

import BoCard from '@/components/BoCard.vue'
import BoCardImg from '@/components/BoCardImg.vue'
import BoTextarea from "@/components/BoTextarea.vue";

export default {
  extends: GlobalVue,
  components: {
    PageTitle,
    BoCard,
    BoCardImg,
    BoTextarea,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      // GENERAL
      lang: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Indonesian",
          value: "id",
        }
      ],
      editor: ClassicEditor,
      editorConfig: {
        fontColor: {
          colors: [
            {
              color: "hsl(221, 99%, 43%)",
              label: "Blue",
            },
            {
              color: "hsl(0, 83%, 47%)",
              label: "Red",
            },
            {
              color: "hsl(93, 69%, 49%)",
              label: "Green",
            },
            {
              color: "hsl(322, 100%, 62%)",
              label: "Pink",
            },
            {
              color: "hsl(0, 0%, 0%)",
              label: "Black",
            },
            {
              color: "hsl(0, 0%, 30%)",
              label: "Dim grey",
            },
            {
              color: "hsl(0, 0%, 60%)",
              label: "Grey",
            },
            {
              color: "hsl(0, 0%, 90%)",
              label: "Light grey",
            },
            {
              color: "hsl(0, 0%, 100%)",
              label: "White",
              hasBorder: true,
            },
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        ui: {
          viewportOffset: {
            top: 65,
          },
        },
        toolbar: {
          items: ["heading", "|", "bold", "fontColor", "link"],
        },
      },

      // ROW
      content: {},

      editFormId: "",

      // INPUT
      inputHeroImage: {}
    };
  },
  created() {
    this.apiGet();
  },
  methods: {
    addIcon(k, vlang) {
      ((this.content['why_jrf_' + vlang.value] || {}).value || {}).data.splice(k + 1, 0, {
        url: '',
        text: '',
        sub_text: '',
      })
    },
    removeIcon(k, vlang) {
      if( k == 0 ) return

      ((this.content['why_jrf_' + vlang.value] || {}).value || {}).data.splice(k, 1)
    },
    // General

    updateContent(type, input, IDFORM, modalId = false, usingKey = false) {
      this.doSubmit(
        "/do/" + this.modulePage,
        assign(
          {
            type,
          },
          {},
          {
            as_value: input,
          }
        ),
        (type) => {
          if (type === "success") {
            this.apiGet();
            this.editFormId = "";
            if (modalId) {
              this.$bvModal.hide(modalId);
            }
          }
        },
        "POST",
        IDFORM,
        usingKey
      );
    },

    // Hero Image
    editHeroImage(data) {
      this.inputHeroImage = {
        ...data
      }

      this.$bvModal.show('heroImageModal')
    },

    // Marquee
    addItem(index, data) {
      data.splice(index + 1, 0, '');
    },
    removeItem(index, data) {
      data.splice(index, 1);
    }
  },
  mounted() {
    extend("editor", {
      message: "The {_field_} field must be at least 10 characters",
      validate: (value) => {
        try {
          return this.sanitize(value).length >= 10;
        } catch (error) {
          console.log(error);
        }
      },
    });
  },
};
</script>

<style lang="scss" scoped>

.add_hero {
  min-height: 130px;
  border: 2px dotted rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.marquee_container {
  // height: 100vh;
  position: relative;
  overflow: hidden;
  width: 100%;
}
.marquee {
  border: 1px solid grey;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -1%;
  width: 102%;
  height: fit-content;
  display: flex;
  gap: 60px;
  overflow: hidden;
}
.marquee_group {
  display: flex;
  flex-shrink: 0;
  gap: 45px;
  min-width: 100%;
  align-items: center;
  justify-content: center;
  animation: scroll 15s linear infinite;
}
.marquee span {
  font-size: 9vw;
  font-weight: 700;
  font-family: 'Sora', sans-serif;
  background: linear-gradient(to right, #0145DC, 
    #D3302B); 
    -webkit-text-fill-color: transparent; 
    -webkit-background-clip: text;   
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 60px));
  }
}
.blobs {
  height: 40vh;
  position: relative;
  @media (max-width: 768px) {
    height: 5vh;
  }
}

.square-item {
  width: 1.5em;
  height: 1.5em;
  background: linear-gradient(to right, #0145DC, 
    #D3302B);
}

</style>